import { useEffect, useState, useRef } from 'react';
import { Form, Alert, Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useReactToPrint } from 'react-to-print';

import { IMuestras } from './../../interfaces/IMuestras';
import { listarMuestrasService } from './../../services/Muestras/listmuestras.service';
import ChartCabeza from '../ListarResultados/charts/ChartCabeza';
import ChartConcentrado from '../ListarResultados/charts/ChartConcentrado';
import ChartPreconcentrado from '../ListarResultados/charts/ChartPreconcentrado';
import ChartGangas from '../ListarResultados/charts/ChartGangas';
import EtiquetaMuestraOne from './EtiquetaMuestraOne';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

export default function EtiquetaMuestra() {
  const pdfsRef = useRef(null);
  const { id }: string | any = useParams();
  const [muestras, setMuestra] = useState<IMuestras>({
    id: 0,
    idMuestra: '',
    proyecto: '',
    region: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboPorRetenido: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    fechaRegistroMuestra: '',
  });
  const generarPDF = useReactToPrint({
    content: () => pdfsRef.current,
    documentTitle: `Laboratorio MSW ${muestras.idMuestra}`,
  });
  const listarMuestra = async () => {
    try {
      const data = await listarMuestrasService(id);
      setMuestra(data);
    } catch (ex) {
      return ex;
    }
  };
  useEffect(() => {
    listarMuestra();
  }, []);
  return (
    <>
      {muestras ? (
        <div className='m-3'>
          <h2 className='text-center m-4 fw-bold'>{muestras.idMuestra}</h2>
          <Form.Group>
            <Form.Label className='fw-bold'>Fecha de Registro:</Form.Label>
            <Form.Label>
              {!!muestras.fechaRegistroMuestra
                ? format(new Date(muestras.fechaRegistroMuestra), 'dd/MM/yyyy')
                : '--/--/----'}
            </Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>Region:</Form.Label>
            <Form.Label>{muestras.region}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>Proyecto:</Form.Label>
            <Form.Label>{muestras.proyecto}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>De (Mts):</Form.Label>
            <Form.Label>{esNumeroEntero(muestras.deMts)}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>A (Mts):</Form.Label>
            <Form.Label>{esNumeroEntero(muestras.aMts)}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label className='fw-bold'>Tramo (Mts):</Form.Label>
            <Form.Label>{esNumeroEntero(muestras.tramoMts)}</Form.Label>
          </Form.Group>
          <Form.Label className='fw-bold fs-5 mt-4'>Resultados</Form.Label>
          <Modal.Title className='tex-deco mt-5'>Cabeza</Modal.Title>
          <ChartCabeza />
          <Modal.Title className='tex-deco mt-5'>Preconcentrado</Modal.Title>
          <div className='d-flex flex-row text-center mt-2 mb-5'>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>Kg Inicial</Form.Label>
              <Form.Control
                className='text-center'
                name='prePesoInicial'
                plaintext
                readOnly
                value={`${muestras.prePesoInicial} kg`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>KG Final</Form.Label>
              <Form.Control
                className='text-center'
                name='prePesoFinal'
                plaintext
                readOnly
                value={`${muestras.prePesoFinal} kg`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
              <Form.Control
                className='text-center'
                name='PrePorRetenido'
                plaintext
                readOnly
                value={`${esNumeroEntero(muestras.prePorRetenido)}%`}
              />
            </Form.Group>
          </div>
          <ChartPreconcentrado />
          <Modal.Title className='tex-deco mt-5'>Concentrado</Modal.Title>
          <div className='d-flex flex-row text-center mt-2 mb-5'>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>Kg Inicial</Form.Label>
              <Form.Control
                className='text-center'
                name='prePesoInicial'
                plaintext
                readOnly
                value={`${muestras.tuboPesoInicial} gr`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>KG Final</Form.Label>
              <Form.Control
                className='text-center'
                name='prePesoFinal'
                plaintext
                readOnly
                value={`${muestras.tuboPesoFinal} gr`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
              <Form.Control
                className='text-center'
                name='PrePorRetenido'
                plaintext
                readOnly
                value={`${esNumeroEntero(muestras.tuboPorRetenido)}%`}
              />
            </Form.Group>
          </div>
          <ChartConcentrado />
          <Modal.Title className='tex-deco mt-5'>Gangas</Modal.Title>
          <ChartGangas />
          <div
            className='invisible'
            style={{ position: 'fixed', zIndex: '-1' }}>
            <EtiquetaMuestraOne pdf={pdfsRef} />
          </div>
        </div>
      ) : (
        <div className='text-center p-3'>
          <Alert variant='danger'>
            No se a encontrado el registro de los resultados de la Muestra
          </Alert>
        </div>
      )}
      <Button className='boton-gris mt-5 mx-1 mb-2' onClick={generarPDF}>
        Generar PDF
      </Button>
    </>
  );
}
