import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Modal, Alert } from 'react-bootstrap';

import { TuboModal } from './../utils/types/type';
import { ITubo } from '../interfaces/IResultados';
import { resultadoT } from './../utils/validaciones/resultadoT.validaciones';
import { agregarTuboService } from '../services/Muestras/agregartubo.service';
import axios from '../axios';
import { AxiosResponse } from 'axios';

export default function TubosModal({
  detalle,
  estadoModal,
  recuperar,
  cambiarEstado,
}: TuboModal) {
  const [tubos, setTubo] = useState<ITubo>({
    id: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    tuboPorRetenido: 0,
  });

  const formik = useFormik({
    initialValues: tubos,
    validationSchema: resultadoT,
    onSubmit: async (values: ITubo) => {
      const { data } = await agregarTuboService(values);
      setTubo(data);
      recuperar(data);
      cambiarEstado(false);
      window.location.reload();
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (estadoModal) {
      axios
        .get(`Muestras/${+detalle.id}`)
        .then((respuesta: AxiosResponse<ITubo>) => {
          setTubo(respuesta.data);
          formik.setValues({
            id: respuesta.data.id,
            tuboFeT: respuesta.data.tuboFeT,
            tuboFeM: respuesta.data.tuboFeM,
            tuboPesoInicial: respuesta.data.tuboPesoInicial,
            tuboPesoFinal: respuesta.data.tuboPesoFinal,
            tuboAluminio: respuesta.data.tuboAluminio,
            tuboCalcio: respuesta.data.tuboCalcio,
            tuboSilicio: respuesta.data.tuboSilicio,
            tuboMagnecio: respuesta.data.tuboMagnecio,
            tuboZinc: respuesta.data.tuboZinc,
            tuboAzufre: respuesta.data.tuboAzufre,
            tuboPorRetenido: respuesta.data.tuboPorRetenido,
          });
        });
    }
  }, [estadoModal]);

  return (
    <>
      <Modal show={estadoModal} onHide={() => cambiarEstado(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='tex-deco'>Concentrado</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          {tubos ? (
            <>
              <Modal.Body style={{ marginTop: '-48px', marginBottom: '30px' }}>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Peso Inicial</Form.Label>
                    <Form.Control
                      autoFocus={true}
                      name='tuboPesoInicial'
                      type='number'
                      isInvalid={!!formik.errors.tuboPesoInicial}
                      onChange={formik.handleChange}
                      value={formik.values.tuboPesoInicial}
                      onFocus={e => e.currentTarget.select()}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboPesoInicial}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Peso Final</Form.Label>
                    <Form.Control
                      name='tuboPesoFinal'
                      type='number'
                      isInvalid={!!formik.errors.tuboPesoFinal}
                      onChange={formik.handleChange}
                      value={formik.values.tuboPesoFinal}
                      onFocus={e => e.currentTarget.select()}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboPesoFinal}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>%RW</Form.Label>
                    <Form.Control
                      name='tuboPorRetenido'
                      type='number'
                      readOnly
                      disabled
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={
                        (formik.values.tuboPorRetenido =
                          (formik.values.tuboPesoFinal * 100) /
                          formik.values.tuboPesoInicial)
                      }
                    />
                  </Form.Group>
                </div>
                <div className='row' style={{ marginTop: '21px' }}>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>FeT</Form.Label>
                    <Form.Control
                      name='tuboFeT'
                      type='number'
                      isInvalid={!!formik.errors.tuboFeT}
                      onChange={formik.handleChange}
                      value={formik.values.tuboFeT}
                      onFocus={e => e.currentTarget.select()}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboFeT}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>FeM</Form.Label>
                    <Form.Control
                      name='tuboFeM'
                      type='number'
                      isInvalid={!!formik.errors.tuboFeM}
                      onChange={formik.handleChange}
                      value={formik.values.tuboFeM}
                      onFocus={e => e.currentTarget.select()}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboFeM}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <Form.Label className='tex-deco fw-bold mt-5 mb-3'>
                  Gangas
                </Form.Label>
                <div className='row' style={{ marginTop: '-55px' }}>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Aluminio(Al)</Form.Label>
                    <Form.Control
                      name='tuboAluminio'
                      type='number'
                      isInvalid={!!formik.errors.tuboAluminio}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboAluminio}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboAluminio}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Calcio (Ca)</Form.Label>
                    <Form.Control
                      name='tuboCalcio'
                      type='number'
                      isInvalid={!!formik.errors.tuboCalcio}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboCalcio}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboCalcio}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Silicio (Si)</Form.Label>
                    <Form.Control
                      name='tuboSilicio'
                      type='number'
                      isInvalid={!!formik.errors.tuboSilicio}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboSilicio}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboSilicio}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='row' style={{ marginTop: '21px' }}>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Magnesio (Mg)</Form.Label>
                    <Form.Control
                      name='tuboMagnecio'
                      type='number'
                      isInvalid={!!formik.errors.tuboMagnecio}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboMagnecio}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboMagnecio}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'>
                    <Form.Label className='tex-deco'>Zinc (Zn)</Form.Label>
                    <Form.Control
                      name='tuboZinc'
                      type='number'
                      isInvalid={!!formik.errors.tuboZinc}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboZinc}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboZinc}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className='tex-deco fw-bold mt-5 mb-3'>
                    Azufre
                  </Form.Label>
                  <Form.Group
                    className='text-left p-2 col col-xs-4 col-sm-4 col-md-4 col-lg-4'
                    style={{ marginTop: '-55px' }}>
                    <Form.Label className='tex-deco'>Azufre (S)</Form.Label>
                    <Form.Control
                      name='tuboAzufre'
                      type='number'
                      isInvalid={!!formik.errors.tuboAzufre}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.tuboAzufre}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.tuboAzufre}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Modal.Body>
            </>
          ) : (
            <div className='text-center p-3'>
              <Alert variant='danger'>
                no se a encontrado el registro del tubo
              </Alert>
            </div>
          )}
          <Modal.Footer>
            <Button
              className='br-cg'
              variant='btn btn-outline-dark'
              onClick={() => cambiarEstado(false)}>
              Cancelar
            </Button>
            <Button className='boton-gris' type='submit'>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
