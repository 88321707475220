import axios from './../axios';
import { IFiltros } from './../interfaces/IFiltros';

export const paginadoService = async (
  numPagina: number,
  cantidadElementos: number,
  filtros: IFiltros
) => {
  try {
    const { data } = await axios.get(
      `Muestras/Paginado/${numPagina}/${cantidadElementos}`,
      {
        params: {
          initialDate: filtros.initialDate,
          finalDate: filtros.finalDate,
          search: filtros.search,
        },
      }
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
};
