import axios from '../../axios';
import { ICabeza } from '../../interfaces/IResultados';

export const listarCabezaService = async (id: string | number) => {
  try {
    const { data } = await axios.get<ICabeza>(`Muestras/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export default listarCabezaService;
