import axios from '../../axios';
import { IPreconcentrado } from '../../interfaces/IResultados';

export const listarPreconcentradoService = async (id: string | number) => {
  try {
    const { data } = await axios.get<IPreconcentrado>(`Muestras/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export default listarPreconcentradoService;
