import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { IPreconcentrado } from '../../../interfaces/IResultados';
import listarPreconcentradoService from '../../../services/Muestras/listpreconcentrado.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
export const optionsC = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  plugins: {
    legend: {
      position: 'right' as const,
      display: false,
    },
    title: {
      display: true,
      text: 'Preconcentrado',
    },
    datalabels: {
      formatter: value => {
        return `${value}%`;
      },
      color: 'white',
    },
  },
};

const ChartWrap = styled.div`
  max-width: 500px;
`;

const ChartPreco = () => {
  const { id }: string | any = useParams();
  const [detalleP, setDetalleP] = useState<IPreconcentrado>({
    id: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
  });

  /*Preconcentrado*/
  const listarPreconcentrado = async () => {
    try {
      const data = await listarPreconcentradoService(id);
      setDetalleP(data);
    } catch (ex) {
      return ex;
    }
  };

  useEffect(() => {
    listarPreconcentrado();
  }, []);

  const precoChartData = () => {
    const data = [detalleP.preFeT, detalleP.preFeM];
    const labels = ['FeT', 'FeM'];

    return {
      labels,
      datasets: [
        {
          data,
          borderColor: ['rgb(049, 084, 160)', 'rgb(150, 152, 154)'],
          backgroundColor: ['rgb(049, 084, 150)', 'rgb(150, 152, 154)'],
        },
      ],
    };
  };
  return (
    <ChartWrap>
      <Bar options={optionsC} data={precoChartData()} width={40} height={20} />
    </ChartWrap>
  );
};

export default ChartPreco;
