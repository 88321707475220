import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { getErrorMessage } from '../utils/error/error';

function useError() {
  const [error, setErrorState] = useState('');

  const onClose = () => setErrorState('');
  const ErrorAlert = (): any =>
    error && (
      <Alert onClose={onClose} dismissible variant='danger'>
        {error}
      </Alert>
    );
  const setError = (e: any) => setErrorState(getErrorMessage(e));

  return { ErrorAlert, setError };
}

export default useError;
