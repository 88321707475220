import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useSesion from '../../hooks/useSesion';
import { ROLES } from './../../contants/app-roles';
import CabezaService from '../../services/Muestras/cabeza.service';
import listarCabezaService from '../../services/Muestras/listcabeza.service';
import { agregarCabezaService } from '../../services/Muestras/agregarcabeza.service';
import CabezasModal from '../../modals/CabezaModal';
import { ICabeza } from '../../interfaces/IResultados';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

const Cabeza = () => {
  const { role } = useSesion();
  const { id }: string | any = useParams();
  const [detalleC, setDetalleC] = useState<ICabeza>({
    id: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
  });

  const [cabeza, setCabeza] = useState<ICabeza[]>([]);
  const [showModalC, setShowModalC] = useState<boolean>(false);

  const openModalC = (values: ICabeza) => {
    setDetalleC(values);
    setShowModalC(true);
  };

  /*Cabeza*/
  const listarCabeza = async () => {
    try {
      const data = await listarCabezaService(id);
      setDetalleC(data);
    } catch (ex) {
      return ex;
    }
  };

  /*editar Cabeza*/
  async function obtenerCabeza() {
    try {
      const data = await CabezaService();
      setCabeza(data);
    } catch (ex) {}
  }
  function editarCabeza(values: ICabeza) {
    const actualizar = cabeza.map((cab: ICabeza) =>
      cab.id === values.id ? values : cab
    );
    setCabeza(() => [...actualizar]);
    agregarCabezaService(values);
    setDetalleC(values);
    setShowModalC(false);
  }
  useEffect(() => {
    listarCabeza();
    obtenerCabeza();
  }, []);

  return (
    <>
      {/* Card de cabeza */}
      <Card
        style={{ width: '14%', marginRight: '1%' }}
        className='crd-color d-none d-md-none d-lg-block'>
        <Card.Body>
          <div className='d-flex bd-highlight'>
            <Card.Title
              className='text-start tex-deco col d-none d-md-none d-lg-block'
              key={detalleC.id}>
              Cabeza
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalC(detalleC)}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {detalleC ? (
            <div>
              <Card.Text>
                <div className='row d-flex tex-deco justify-content-center'>
                  <Form.Group className='text-center col p-2'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='cabezaFeT'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleC.cabezaFeT)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center col p-2'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='cabezaFeM'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleC.cabezaFeM)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-4'>
                No se a encontrado el registro de
                <span className='fw-bold'> Cabeza</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      {/* Card de cabeza responsiva*/}
      <Card
        style={{ width: '99%', marginBottom: '1%' }}
        className='crd-color d-block d-md-block d-lg-none'>
        <Card.Body>
          <div className='d-flex bd-highlight justify-content-end'>
            <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'>
              Cabeza
            </Card.Title>
            <Card.Title className='text-center tex-deco col d-block d-sm-block d-md-none'>
              Cabeza
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalC(detalleC)}>
                <EditIcon />
              </IconButton>
            )}
          </div>

          {detalleC ? (
            <div>
              <Card.Text className='row'>
                <div className='col d-flex justify-content-center tex-deco'>
                  <Form.Group className='text-center col col-xs-2 col-sm-2 col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='cabezaFeT'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleC.cabezaFeT)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col d-flex justify-content-center tex-deco'>
                  <Form.Group className='text-center col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='cabezaFeM'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleC.cabezaFeM)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-4'>
                No se a encontrado el registro de
                <span className='fw-bold'> Cabeza</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      <CabezasModal
        detalle={detalleC}
        recuperar={editarCabeza}
        estadoModal={showModalC}
        cambiarEstado={setShowModalC}
      />
    </>
  );
};

export default Cabeza;
