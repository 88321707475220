import axios from '../../axios';
import { ITubo } from '../../interfaces/IResultados';

export const listarTuboService = async (id: string | number) => {
  try {
    const { data } = await axios.get<ITubo>(`Muestras/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export default listarTuboService;
