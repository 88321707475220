import { useState, useRef, useEffect } from 'react';
import {
  Col,
  Table,
  Form,
  Button,
  Row,
  Container,
  Spinner,
} from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Link } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';
import axios from '../../axios';
import {
  IMuestras,
  IDMuestra,
  IListMuestras,
} from '../../interfaces/IMuestras';
import { ILoading } from './../../interfaces/ILoading';
import { AppRoutes } from '../../contants/app-routes';
import useSesion from '../../hooks/useSesion';
import { ROLES } from './../../contants/app-roles';
import { borrarMuestraService } from '../../services/Muestras/borrarmuestra.service';
import { editarMuestraService } from '../../services/Muestras/editarmuestras.service';
import MuestrasModal from '../../modals/MuestrasModal';
import ListMuestrasModal from '../../modals/ListMuestraModal';
import DeleteMuestraModal from '../../modals/DeleteMuestraModal';
import Loading from './../../components/Loading';
import Paginacion from './../../components/Paginacion/Paginacion';
import ChartMuestraCFeM from './ChartMuestraCFeM';
import ChartMuestraCFeT from './ChartMuestraCFeT';
import ChartMuestraPFeM from './ChartMuestraPFeM';
import ChartMuestraPFeT from './ChartMuestraPFeT';
import ChartMuestraTFeM from './ChartMuestraTFeM';
import ChartMuestraTFeT from './ChartMuestraTFeT';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

import './ListaMuestra.scss';
import { paginadoService } from './../../services/paginado.service';
import { exportExcelService } from './../../services/exportExcel.service';

export default function ListaMuestra() {
  const [numeroDeRegistros, setNumeroDeRegistros] = useState<number>(50);
  const [carga, setCarga] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [loading, setLoading] = useState<ILoading>({
    loading: false,
    mensaje: '',
  });
  const { role } = useSesion();
  const [muestras, setMuestras] = useState<IListMuestras>({
    muestras: [],
    totalMuestras: 0,
  });
  const [muestrasSeleccionadas, setMuestrasSeleccionadas] = useState<
    IMuestras[]
  >([]);
  const [paginaciones, setPaginaciones] = useState<{
    pagina: number;
    recordsPorPagina: number;
    masRegistros: boolean;
  }>({
    pagina: 1,
    recordsPorPagina: 50,
    masRegistros: false,
  });
  const [filtros, setFiltros] = useState({
    initialDate: '',
    finalDate: '',
    search: '',
  });
  const [optionSelected, setOptionSelected] = useState<string>('');
  const [elementoSelected, setElementoSelected] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDos, setShowModalDos] = useState<boolean>(false);
  const [showModalTres, setShowModalTres] = useState<boolean>(false);
  const [detalle, setDetalle] = useState<IMuestras>({
    id: 0,
    idMuestra: '',
    proyecto: '',
    region: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboPorRetenido: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    fechaRegistroMuestra: '',
  });
  const pdfsRef = useRef(null);
  const path = window.location.origin;

  const imprimirQRs = useReactToPrint({
    content: () => pdfsRef.current,
    documentTitle: 'Muestras',
    onAfterPrint: () => setLoad(false),
  });

  const openModal = () => {
    setShowModal(true);
  };

  const openModalDos = (values: IMuestras) => {
    setDetalle(values);
    setShowModalDos(true);
  };

  const openModalTres = (values: IMuestras) => {
    setDetalle(values);
    setShowModalTres(true);
  };
  async function exportExcel() {
    try {
      setCarga(true);
      const { data } = await exportExcelService(filtros);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Muestras.xlsx');
      document.body.appendChild(link);
      link.click();
    } catch (ex) {
    } finally {
      setCarga(false);
    }
  }
  const descargarQR = () => {
    const templateQR = (
      <div
        className='d-flex align-items-center justify-content-between flex-wrap page-break'
        ref={pdfsRef}>
        {muestrasSeleccionadas.length &&
          muestrasSeleccionadas
            .sort(
              (primero: IMuestras, segundo: IMuestras) =>
                +primero.id - +segundo.id
            )
            .map((item: IMuestras) => (
              <>
                <div
                  key={item.id}
                  className='my-2 py-2 px-3 pt-3 d-flex align-items-center justify-content-center flex-column'
                  style={{ border: 'solid 2px #000000', width: '150px' }}>
                  <QRCode
                    value={`${path}${AppRoutes.EtiquetaMuestras}/${item.id}`}
                    size={90}
                  />
                  <p
                    className='fw-bold m-0 mt-2 text-center'
                    style={{ fontSize: '14px', width: '150px' }}>
                    {item.idMuestra}
                    <br />
                    Cabeza
                  </p>
                </div>
                <div
                  key={item.id}
                  className='my-2 py-2 px-3 pt-3 d-flex align-items-center justify-content-center flex-column'
                  style={{ border: 'solid 2px #000000', width: '150px' }}>
                  <QRCode
                    value={`${path}${AppRoutes.EtiquetaMuestras}/${item.id}`}
                    size={90}
                  />
                  <p
                    className='fw-bold m-0 mt-2 text-center'
                    style={{ fontSize: '14px' }}>
                    {item.idMuestra}
                    <br />
                    Preconcentrado
                  </p>
                </div>
                <div
                  key={item.id}
                  className='my-2 py-2 px-3 pt-3 d-flex align-items-center justify-content-center flex-column'
                  style={{ border: 'solid 2px #000000', width: '150px' }}>
                  <QRCode
                    value={`${path}${AppRoutes.EtiquetaMuestras}/${item.id}`}
                    size={90}
                  />
                  <p
                    className='fw-bold m-0 mt-2 text-center'
                    style={{ fontSize: '14px' }}>
                    {item.idMuestra}
                    <br />
                    Concentrado
                  </p>
                </div>
              </>
            ))}
      </div>
    );
    ReactDOM.render(templateQR, document.querySelector('#qrs-container'));
  };

  const seleccionarMuestra = (estado: boolean, seleccionada: IMuestras) => {
    if (estado) {
      setMuestrasSeleccionadas((mst: IMuestras[]) => [...mst, seleccionada]);
    } else {
      const existeMuestra = muestrasSeleccionadas.some(
        (mus: IMuestras) => mus.id === seleccionada.id
      );
      if (existeMuestra) {
        setMuestrasSeleccionadas((mst: IMuestras[]) =>
          mst.filter((mst: IMuestras) => mst.id !== seleccionada.id)
        );
      }
    }
  };

  const seleccionarTodasMuestras = (estado: boolean) => {
    if (estado) {
      setMuestrasSeleccionadas([...muestras.muestras]);
      muestras.muestras.map((item: IMuestras) => {
        const checkElement: HTMLInputElement | null = document.querySelector(
          `#check-${item.id}`
        );
        if (checkElement) {
          checkElement.checked = estado;
        }
      });
    } else {
      setMuestrasSeleccionadas([]);
      muestras.muestras.map((item: IMuestras) => {
        const checkElement: HTMLInputElement | null = document.querySelector(
          `#check-${item.id}`
        );
        if (checkElement) {
          checkElement.checked = estado;
        }
      });
    }
  };

  const obtenerMuestras = async (
    numPagina: number = paginaciones.pagina,
    cantidadElementos: number = paginaciones.recordsPorPagina
  ) => {
    try {
      setLoading({
        loading: true,
        mensaje: 'Obteniendo muestras',
      });
      const data = await paginadoService(numPagina, cantidadElementos, filtros);
      setMuestras(muestras => ({ ...muestras, muestras: data.data.muestras }));
      const records = Math.ceil(data.data.totalMuestras / cantidadElementos);
      setPaginaciones({
        pagina: numPagina,
        recordsPorPagina: records,
        masRegistros: !!data.data.muestras.length,
      });
    } catch (ex) {
      return ex;
    } finally {
      setLoading({
        loading: false,
        mensaje: '',
      });
    }
  };

  function recuperarMuestra(muestra: IMuestras) {
    setMuestras((detalle: IListMuestras) => ({
      ...detalle,
      muestras: [...muestras.muestras, muestra],
    }));
  }

  function editarMuestra(values: IMuestras) {
    const actualizar = muestras.muestras.map((mt: IMuestras) =>
      mt.id === values.id ? values : mt
    );
    setMuestras((mst: IListMuestras) => ({ ...mst, muestras: actualizar }));
    editarMuestraService(values);
    setDetalle(values);
    setShowModalDos(false);
  }

  function eliminarMuestra(value: IDMuestra) {
    borrarMuestraService(value);
    const nuevo = muestras.muestras.filter(
      (muestra: IMuestras) => muestra.id !== value.id
    );
    setMuestras((mst: IListMuestras) => ({ ...mst, muestras: nuevo }));
    setShowModalTres(false);
  }
  useEffect(() => {
    setLoading({
      loading: false,
      mensaje: '',
    });
    descargarQR();
  }, [muestrasSeleccionadas]);

  useEffect(() => {
    obtenerMuestras(1, numeroDeRegistros);
  }, [filtros]);

  return (
    <>
      <Container className='d-flex flex-column mt-5' fluid>
        <div id='qrs-container' className='d-none'></div>
        <h2 className='text-center m-2'>Lista de Muestras</h2>
        {loading.loading && <Loading mensaje={loading.mensaje} />}
        <Row className='d-flex align-items-end '>
          <Col sm={2} md={2}>
            <Form.Group>
              <Form.Control
                name='muestra'
                placeholder='Muestra,Region,Proyecto'
                onChange={({ target }) =>
                  setFiltros(filtro => ({ ...filtro, search: target.value }))
                }
                value={filtros.search}
              />
            </Form.Group>
          </Col>
          <Col sm={3} md={1}>
            <Form.Group>
              <Form.Label>Resultados</Form.Label>
              <Form.Control
                as='select'
                value={optionSelected}
                onChange={({ target }) => setOptionSelected(target.value)}>
                <option>Seleccione Una Opcion</option>
                <option value='cabeza'>Cabeza</option>
                <option value='preconcentrado'>Preconcentrado</option>
                <option value='concentrado'>Concentrado</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col sm={3} md={1}>
            {optionSelected && (
              <Form.Group>
                <Form.Label>Elementos</Form.Label>
                <Form.Control
                  as='select'
                  value={elementoSelected}
                  onChange={({ target }) => setElementoSelected(target.value)}>
                  <option>Seleccione Una Opcion</option>
                  <option value='FeT'>FeT</option>
                  <option value='FeM'>FeM</option>
                </Form.Control>
              </Form.Group>
            )}
          </Col>
          {role?.includes(ROLES.ADMINISTRADOR) && (
            <Col className='d-flex align-items-end justify-content-end'>
              <Button
                className='boton-gris mt-5 mx-1'
                onClick={() => exportExcel()}>
                {carga ? (
                  <>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                    &nbsp;&nbsp;
                  </>
                ) : (
                  'Exportar Excel'
                )}
              </Button>
              <Button
                className='boton-gris mt-5 mx-1'
                onClick={() => {
                  setLoad(true);
                  imprimirQRs();
                }}
                disabled={!!!muestrasSeleccionadas.length}>
                {load ? (
                  <>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                    &nbsp;&nbsp;
                  </>
                ) : (
                  'Imprimir Etiquetas'
                )}
              </Button>
              <Button
                className='button-add-user mt-5 mx-1'
                onClick={() => openModal()}>
                Agregar Muestra
              </Button>
            </Col>
          )}
        </Row>
        <Row className='d-flex align-items-end mt-2'>
          <Col className='align-items-end justify-content-end' sm={3} md={2}>
            <Form.Group>
              <Form.Label>Fecha Registro Mayor a </Form.Label>
              <Form.Control
                type='date'
                onChange={({ target }) =>
                  setFiltros(filtro => ({
                    ...filtro,
                    initialDate: target.value,
                  }))
                }
                value={filtros.initialDate}
              />
            </Form.Group>
          </Col>
          <Col className='align-items-end justify-content-end' sm={3} md={2}>
            <Form.Group>
              <Form.Label>Fecha Registro Menor a </Form.Label>
              <Form.Control
                type='date'
                onChange={({ target }) =>
                  setFiltros(filtro => ({
                    ...filtro,
                    finalDate: target.value,
                  }))
                }
                value={filtros.finalDate}
              />
            </Form.Group>
          </Col>
          {role?.includes(ROLES.ADMINISTRADOR) && (
            <Col className='d-flex align-items-end justify-content-end'>
              <Form>
                <Form.Check
                  type='checkbox'
                  id='todos'
                  label='Seleccionar Todo'
                  onChange={({ target }) =>
                    seleccionarTodasMuestras(target.checked)
                  }
                />
              </Form>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12} sm={12} xs={12} className='mt-2'>
            <Table striped bordered hover responsive>
              <thead className='thead-light'>
                <tr>
                  <th>IdMuestra</th>
                  <th>Región</th>
                  <th>Proyecto</th>
                  <th>De (Mts)</th>
                  <th>A (Mts)</th>
                  <th>Tramo (Mts)</th>
                  <th>FeT (%)</th>
                  <th>FeM (%)</th>
                  {role?.includes(ROLES.ADMINISTRADOR) && <th>Imp. QR</th>}
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {muestras.muestras.length ? (
                  <>
                    {muestras.muestras.map((mus: IMuestras) => (
                      <tr key={mus?.id}>
                        <td>{mus?.idMuestra}</td>
                        <td>{mus?.region}</td>
                        <td>{mus?.proyecto}</td>
                        <td>{esNumeroEntero(mus?.deMts)}</td>
                        <td>{esNumeroEntero(mus?.aMts)}</td>
                        <td>{esNumeroEntero(mus?.tramoMts)}</td>
                        <td>{esNumeroEntero(mus?.cabezaFeT)}</td>
                        <td>{esNumeroEntero(mus?.cabezaFeM)}</td>

                        {role?.includes(ROLES.ADMINISTRADOR) && (
                          <td>
                            <Form>
                              <Form.Check
                                className='checkbox-qr'
                                type='checkbox'
                                id={`check-${mus.id}`}
                                onChange={({ target }) =>
                                  seleccionarMuestra(target.checked, mus)
                                }
                              />
                            </Form>
                          </td>
                        )}

                        <td>
                          <Link to={`/ListarResultados/${mus.id}`}>
                            <IconButton className='a-color'>
                              <FilterAltIcon />
                            </IconButton>
                          </Link>
                          {role?.includes(ROLES.ADMINISTRADOR) && (
                            <>
                              <IconButton
                                className='a-color'
                                onClick={() => openModalDos(mus)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                className='a-color'
                                onClick={() => openModalTres(mus)}>
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={10}>No hay muestras</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
            <div className='d-flex flex-row-reverse bd-highlight'>
              <div className='p-2 bd-highlight'>
                <Paginacion
                  cantidadTotalDePaginas={paginaciones.recordsPorPagina}
                  paginaActual={paginaciones.pagina}
                  onChange={nuevaPagina =>
                    obtenerMuestras(nuevaPagina, numeroDeRegistros)
                  }
                />
              </div>
              <div className='p-2 bd-highlight form-group'>
                <select
                  className='form-control sel'
                  defaultValue={50}
                  onChange={({ target }) => {
                    setNumeroDeRegistros(+target.value);
                    obtenerMuestras(1, +target.value);
                  }}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={70}>70</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
        <ListMuestrasModal
          editar={editarMuestra}
          estadoModal={showModalDos}
          cambiarEstado={setShowModalDos}
          detalle={detalle}
        />
        <MuestrasModal
          recuperar={recuperarMuestra}
          estadoModal={showModal}
          cambiarEstado={setShowModal}
        />
        <DeleteMuestraModal
          estadoModal={showModalTres}
          cambiarEstado={setShowModalTres}
          eliminar={eliminarMuestra}
          detalle={detalle}
        />
      </Container>
      {optionSelected === 'cabeza' && elementoSelected === 'FeM' && (
        <ChartMuestraCFeM muestras={muestras.muestras} />
      )}
      {optionSelected === 'cabeza' && elementoSelected === 'FeT' && (
        <ChartMuestraCFeT muestras={muestras.muestras} />
      )}
      {optionSelected === 'preconcentrado' && elementoSelected === 'FeM' && (
        <ChartMuestraPFeM muestras={muestras.muestras} />
      )}
      {optionSelected === 'preconcentrado' && elementoSelected === 'FeT' && (
        <ChartMuestraPFeT muestras={muestras.muestras} />
      )}
      {optionSelected === 'concentrado' && elementoSelected === 'FeM' && (
        <ChartMuestraTFeM muestras={muestras.muestras} />
      )}
      {optionSelected === 'concentrado' && elementoSelected === 'FeT' && (
        <ChartMuestraTFeT muestras={muestras.muestras} />
      )}
    </>
  );
}
