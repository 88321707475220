import { Modal, Button } from 'react-bootstrap';

import { BorrarMuestraModal } from './../utils/types/type';

export default function DeleteMuestraModal({
  estadoModal,
  eliminar,
  cambiarEstado,
  detalle,
}: BorrarMuestraModal) {
  return (
    <Modal show={estadoModal} onHide={() => cambiarEstado(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar Muestra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estas seguro de Eliminar la muestra y todos sus datos?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='btn-light border-dark'
          onClick={() => cambiarEstado(false)}>
          Cerrar
        </Button>
        <Button
          className='btn-danger'
          onClick={() => eliminar({ id: detalle.id })}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
