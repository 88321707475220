import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { PropsMuestras } from '../../utils/types/type';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const optionsM = {
  indexAxis: 'x' as const,
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Concentrado FeT',
    },
    datalabels: {
      formatter: value => {
        return `${value}%`;
      },
      color: 'white',
    },
  },
};

const ChartWrap = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const ChartMuestraTFeT: React.FunctionComponent<PropsMuestras> = ({
  muestras,
}) => {
  const muestraChartData = () => {
    const data: number[] = [];
    const labels: string[] = [];

    muestras.forEach(muestras => {
      data.push(muestras.tuboFeT);
      labels.push(muestras.idMuestra);
    });
    return {
      labels,
      datasets: [
        {
          label: 'FeT',
          data,
          borderColor: 'rgb(049, 084, 160)',
          backgroundColor: 'rgb(049, 084, 150)',
          max: 100,
          min: 0,
        },
      ],
    };
  };
  return (
    <ChartWrap>
      <Bar
        options={optionsM}
        data={muestraChartData()}
        width={100}
        height={30}
      />
    </ChartWrap>
  );
};

export default ChartMuestraTFeT;
