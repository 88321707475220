import axios from './../axios';
import { IAddUser } from './../interfaces/IAddUser';

export const agregarUsuarioService = async (dataRequest: IAddUser) => {
  try {
    const { data } = await axios.post('User/AddUser', dataRequest);
    return data;
  } catch (ex) {
    throw ex;
  }
};
