import axios from '../../axios';

export async function CabezaService() {
  try {
    const { data } = await axios.get('Muestras/Cabeza');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export default CabezaService;
