import { useState, useEffect } from 'react';
import { Button, Form, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

import { IUsers } from './../../interfaces/IUsers';
import DeleteUserModal from '../../modals/DeleteUserModal';
import { IDeleteUser } from './../../interfaces/IDeleteUser';
import { borrarUsuarioService } from './../../services/borrarusuario.service';
import usuariosService from '../../services/usuarios.service';
import AgregarUsuarioModal from '../../modals/AgregarUsuarioModal';
import { AppRoutes } from '../../contants/app-routes';

import './Usuarios.scss';

export default function Usuarios() {
  const [usuarios, setUsuarios] = useState<IUsers[]>([]);
  const [store, setStore] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [detalle, setDetalle] = useState<IUsers>({
    id: '',
    numero: 0,
    userName: '',
    email: '',
  });
  const openModal = () => {
    setShowModal(true);
  };
  const openModal2 = (values: IUsers) => {
    setDetalle(values);
    setShowModal2(true);
  };
  async function obtenerUsuarios() {
    try {
      const data = await usuariosService();
      setUsuarios(data);
    } catch (ex) {}
  }
  function recuperarUsuario(usuario: IUsers) {
    setUsuarios(usuarios => [...usuarios, usuario]);
    setShowModal(false);
  }
  function eliminarUsuario(value: IDeleteUser) {
    borrarUsuarioService(value);
    const nuevo = usuarios.filter(usuario => usuario.id !== value.id);
    setUsuarios(nuevo);
    setShowModal2(false);
  }
  useEffect(() => {
    obtenerUsuarios();
  }, []);
  return (
    <>
      <div className='col align-items-center justify-content-center p-4'>
        <Link to={AppRoutes.ListaMuestra} className='back-color'>
          Regresar
        </Link>
        <h2 className=' text-center m-2'>Lista de Usuarios</h2>
      </div>
      <div className='col d-flex align-items-center justify-content-between mb-4 p-4'>
        <Col sm={6} md={4}>
          <Form.Group>
            <Form.Control
              name='usuario'
              className='form-control w-50'
              placeholder='Nombre del Usuario'
              onChange={e => setStore(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col sm={6} md={3} className='d-flex justify-content-end'>
          <Button className='button-add-user' onClick={() => openModal()}>
            Agregar usuario
          </Button>
        </Col>
      </div>
      <div className='p-4'>
        <Col sm={12} md={12}>
          <Table striped bordered hover responsive>
            <thead className='thead-light'>
              <tr>
                <th>#</th>
                <th>Usuario</th>
                <th>Email</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.length ? (
                <>
                  {usuarios
                    .filter(usr =>
                      usr.userName.toLowerCase().includes(store.toLowerCase())
                    )
                    .map((usr: IUsers) => (
                      <tr key={usr?.id}>
                        <td>{usr?.numero}</td>
                        <td>{usr?.userName}</td>
                        <td>{usr?.email}</td>
                        <td>
                          <IconButton>
                            <SyncIcon className='button-reload-password' />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon
                              className='button-delete'
                              onClick={() => openModal2(usr)}
                            />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={4}>No hay usuarios</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </Col>
        <AgregarUsuarioModal
          recuperar={recuperarUsuario}
          estadoModal={showModal}
          cambiarEstado={setShowModal}
        />
        <DeleteUserModal
          estadoModal={showModal2}
          cambiarEstado={setShowModal2}
          eliminar={eliminarUsuario}
          detalle2={detalle}
        />
      </div>
    </>
  );
}
