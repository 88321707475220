import axios from '../../axios';
import { IMuestras } from '../../interfaces/IMuestras';

export async function editarMuestraService(muestraEditar: IMuestras) {
  try {
    const { data } = await axios.put(
      `Muestras/${muestraEditar.id}`,
      muestraEditar
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
}
