import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useSesion from '../../hooks/useSesion';
import { ROLES } from './../../contants/app-roles';
import { IPreconcentrado } from '../../interfaces/IResultados';
import listarPreconcentradoService from '../../services/Muestras/listpreconcentrado.service';
import { PreconcentradoSevice } from '../../services/Muestras/preconcentrado.service';
import { agregarPreconcentradoService } from '../../services/Muestras/agregarpreconcentrado';
import PreconcentradosModal from '../../modals/PreconcentradoModal';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

const Preconcentrado = () => {
  const { role } = useSesion();
  const { id }: string | any = useParams();
  const [detalleP, setDetalleP] = useState<IPreconcentrado>({
    id: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
  });

  const [preco, setPreconcentrado] = useState<IPreconcentrado[]>([]);
  const [showModalP, setShowModalP] = useState<boolean>(false);

  const openModalP = (values: IPreconcentrado) => {
    setDetalleP(values);
    setShowModalP(true);
  };

  /*Preconcentrado*/
  const listarPreconcentrado = async () => {
    try {
      const data = await listarPreconcentradoService(id);
      setDetalleP(data);
    } catch (ex) {
      return ex;
    }
  };

  /*editar Preconcentrado*/
  async function obtenerPreconcentrado() {
    try {
      const data = await PreconcentradoSevice();
      setPreconcentrado(data);
    } catch (ex) {}
  }
  function editarPreco(values: IPreconcentrado) {
    const actualizar = preco.map((pre: IPreconcentrado) =>
      pre.id === values.id ? values : pre
    );
    setPreconcentrado(() => [...actualizar]);
    agregarPreconcentradoService(values);
    setDetalleP(values);
    setShowModalP(false);
  }
  useEffect(() => {
    listarPreconcentrado();
    obtenerPreconcentrado();
  }, []);

  return (
    <>
      {/* Card de preconcentrado */}
      <Card
        style={{ width: '30%', marginRight: '1%' }}
        className='crd-color d-none d-md-none d-lg-block'>
        <Card.Body>
          <div className='d-flex bd-highlight'>
            <Card.Title
              className='text-start tex-deco col d-none d-md-none d-lg-block'
              key={detalleP.id}>
              Preconcentrado
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalP(detalleP)}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {detalleP ? (
            <div>
              <Card.Text>
                <div className='row d-flex tex-deco justify-content-center'>
                  <Form.Group className='text-center p-2 col col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>
                      Kg Inicial
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='prePesoInicial'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePesoInicial)} kg`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>
                      KG Final
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='prePesoFinal'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePesoFinal)} kg`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='PrePorRetenido'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePorRetenido)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='preFeT'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.preFeT)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-md-2 col-lg-2'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='preFeM'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.preFeM)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-5'>
                No se a encontrado el registro de
                <span className='fw-bold'> Preconcentrado</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      {/* Card de preconcentrado responsiva*/}
      <Card
        style={{ width: '99%', marginBottom: '1%' }}
        className='crd-color d-block d-md-block d-lg-none'>
        <Card.Body>
          <div className='d-flex bd-highlight justify-content-end'>
            <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'>
              Preconcentrado
            </Card.Title>
            <Card.Title className='text-center tex-deco col d-block d-sm-block d-md-none'>
              Preconcentrado
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalP(detalleP)}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {detalleP ? (
            <div>
              <Card.Text>
                <div className='row tex-deco d-flex justify-content-center'>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      Kg Inicial
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='prePesoInicial'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePesoInicial)} kg`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      KG Final
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='prePesoFinal'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePesoFinal)} kg`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='PrePorRetenido'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.prePorRetenido)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='preFeT'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.preFeT)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='preFeM'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleP.preFeM)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-5'>
                No se a encontrado el registro de
                <span className='fw-bold'> Preconcentrado</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      <PreconcentradosModal
        detalle={detalleP}
        recuperar={editarPreco}
        estadoModal={showModalP}
        cambiarEstado={setShowModalP}
      />
    </>
  );
};

export default Preconcentrado;
