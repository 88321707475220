import axios from '../../axios';
import { ICabeza } from '../../interfaces/IResultados';

export async function agregarCabezaService(cabezaEditar: ICabeza) {
  try {
    const { data } = await axios.put(
      `Muestras/Cabeza/${cabezaEditar.id}`,
      cabezaEditar
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
}
