import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import axios from '../axios';
import { AxiosResponse } from 'axios';
import { ListMuestraModal } from './../utils/types/type';
import { editarMuestraService } from '../services/Muestras/editarmuestras.service';
import { IMuestras } from '../interfaces/IMuestras';

import './ModalMuestra.scss';

export default function ListMuestrasModal({
  estadoModal,
  editar,
  cambiarEstado,
  detalle,
}: ListMuestraModal) {
  const [muestras, setMuestras] = useState<IMuestras>({
    id: 0,
    idMuestra: '',
    region: '',
    proyecto: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboPorRetenido: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    fechaRegistroMuestra: '',
  });

  const formik = useFormik({
    initialValues: muestras,
    onSubmit: async (values: IMuestras) => {
      const { data } = await editarMuestraService(values);
      setMuestras(data);
      editar(data);
      cambiarEstado(false);
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (estadoModal) {
      axios
        .get(`Muestras/${+detalle.id}`)
        .then((respuesta: AxiosResponse<IMuestras>) => {
          setMuestras(respuesta.data);
          formik.setValues({
            id: respuesta.data.id,
            idMuestra: respuesta.data.idMuestra,
            region: respuesta.data.region,
            proyecto: respuesta.data.proyecto,
            deMts: respuesta.data.deMts,
            aMts: respuesta.data.aMts,
            tramoMts: respuesta.data.tramoMts,
            cabezaFeT: respuesta.data.cabezaFeT,
            cabezaFeM: respuesta.data.cabezaFeM,
            preFeT: respuesta.data.preFeT,
            preFeM: respuesta.data.preFeM,
            prePesoInicial: respuesta.data.prePesoInicial,
            prePesoFinal: respuesta.data.prePesoFinal,
            prePorRetenido: respuesta.data.prePorRetenido,
            tuboFeT: respuesta.data.tuboFeT,
            tuboFeM: respuesta.data.tuboFeM,
            tuboPesoInicial: respuesta.data.tuboPesoInicial,
            tuboPesoFinal: respuesta.data.tuboPesoFinal,
            tuboPorRetenido: respuesta.data.tuboPorRetenido,
            tuboAluminio: respuesta.data.tuboAluminio,
            tuboCalcio: respuesta.data.tuboCalcio,
            tuboSilicio: respuesta.data.tuboSilicio,
            tuboMagnecio: respuesta.data.tuboMagnecio,
            tuboZinc: respuesta.data.tuboZinc,
            tuboAzufre: respuesta.data.tuboAzufre,
            fechaRegistroMuestra: respuesta.data.fechaRegistroMuestra,
          });
        });
    }
  }, [estadoModal]);

  return (
    <>
      <Modal show={estadoModal} onHide={() => cambiarEstado(false)} centered>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Muestra</Modal.Title>
          </Modal.Header>
          {muestras ? (
            <>
              <Modal.Body>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>IdMuestras</Form.Label>
                    <Form.Control
                      autoFocus={true}
                      name='idMuestra'
                      isInvalid={!!formik.errors.idMuestra}
                      onChange={formik.handleChange}
                      value={formik.values.idMuestra}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>Región</Form.Label>
                    <Form.Control
                      name='region'
                      isInvalid={!!formik.errors.region}
                      onChange={formik.handleChange}
                      value={formik.values.region}
                      onFocus={e => e.currentTarget.select()}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>Proyecto</Form.Label>
                    <Form.Control
                      name='proyecto'
                      onChange={formik.handleChange}
                      value={formik.values.proyecto}
                      onFocus={e => e.currentTarget.select()}
                    />
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>De (Mts)</Form.Label>
                    <Form.Control
                      name='deMts'
                      type='number'
                      onChange={formik.handleChange}
                      value={formik.values.deMts}
                      onFocus={e => e.currentTarget.select()}
                    />
                  </Form.Group>
                </div>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>A (Mts)</Form.Label>
                    <Form.Control
                      name='aMts'
                      type='number'
                      onChange={formik.handleChange}
                      value={formik.values.aMts}
                      onFocus={e => e.currentTarget.select()}
                    />
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label>Tramo (Mts)</Form.Label>
                    <Form.Control
                      name='tramoMts'
                      type='number'
                      readOnly
                      disabled
                      onChange={formik.handleChange}
                      value={
                        (formik.values.tramoMts =
                          formik.values.aMts - formik.values.deMts)
                      }
                    />
                  </Form.Group>
                </div>
              </Modal.Body>
            </>
          ) : (
            <div className='text-center p-3'>
              <Alert variant='danger'>
                no se a encontrado el registro de la Muestra
              </Alert>
            </div>
          )}
          <Modal.Footer>
            <Button
              className='br-cg'
              variant='btn btn-outline-dark'
              onClick={() => cambiarEstado(false)}>
              Cancelar
            </Button>
            <Button className='boton-gris' type='submit'>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
