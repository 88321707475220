import axios from '../../axios';
import { IMuestras } from '../../interfaces/IMuestras';

export const listarMuestrasService = async (id: string | number) => {
  try {
    const { data } = await axios.get<IMuestras>(`Muestras/${id}`);
    return data;
  } catch (ex) {
    throw ex;
  }
};

export default listarMuestrasService;
