import { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AppRoutes } from '../../contants/app-routes';

import { IMuestras } from '../../interfaces/IMuestras';

import listarMuestrasService from '../../services/Muestras/listmuestras.service';
import Cabeza from './CabezaResultados';
import Preconcentrado from './PreconcentradoResultado';
import Concentrado from './TuboResultados';
import ChartCabeza from './charts/ChartCabeza';
import ChartConcentrado from './charts/ChartConcentrado';
import ChartPreconcentrado from './charts/ChartPreconcentrado';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

import './ListarResultados.scss';

export default function ListarResultados() {
  const { id }: string | any = useParams();
  const [muestras, setMuestra] = useState<IMuestras>({
    id: 0,
    idMuestra: '',
    region: '',
    proyecto: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboPorRetenido: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    fechaRegistroMuestra: '',
  });

  /*Muestra*/

  const listarMuestra = async () => {
    try {
      const data = await listarMuestrasService(id);
      setMuestra(data);
    } catch (ex) {
      return ex;
    }
  };

  useEffect(() => {
    listarMuestra();
  }, []);

  return (
    <>
      <div
        className='row justify-content-center'
        style={{ marginRight: '1%', marginLeft: '1%' }}>
        {muestras ? (
          <>
            <div className='col align-items-center justify-content-center p-4'>
              <Link to={AppRoutes.ListaMuestra} className='back-color'>
                Regresar
              </Link>
              <h2 className='text-center m-2'>{muestras.idMuestra}</h2>
            </div>
            <div className='d-none d-sm-none d-md-block'>
              <div className='d-flex flex-wrap align-items-center justify-content-center p-1'>
                <label className='fw-bold'>Región:</label>
                <div
                  className='col col-xs-3 col-sm-2 col-md-2 col-lg-2'
                  style={{ margin: '1%' }}>
                  <Form.Group>
                    <Form.Control
                      name='region'
                      plaintext
                      readOnly
                      value={muestras.region}
                    />
                  </Form.Group>
                </div>
                <label className='fw-bold'>Proyecto:</label>
                <div
                  className='col col-xs-3 col-sm-2 col-md-2 col-lg-2'
                  style={{ margin: '1%' }}>
                  <Form.Group>
                    <Form.Control
                      name='proyecto'
                      plaintext
                      readOnly
                      value={muestras.proyecto}
                    />
                  </Form.Group>
                </div>
                <label className='fw-bold'>De (Mts):</label>
                <div
                  className='col col-xs-3 col-sm-2 col-md-1 col-lg-1'
                  style={{ margin: '1%' }}>
                  <Form.Group>
                    <Form.Control
                      name='deMts'
                      plaintext
                      readOnly
                      value={esNumeroEntero(muestras.deMts)}
                    />
                  </Form.Group>
                </div>
                <label className='fw-bold'>A (Mts):</label>
                <div
                  className='col col-xs-3 col-sm-2 col-md-1 col-lg-1'
                  style={{ margin: '1%' }}>
                  <Form.Group>
                    <Form.Control
                      name='aMts'
                      plaintext
                      readOnly
                      value={esNumeroEntero(muestras.aMts)}
                    />
                  </Form.Group>
                </div>
                <label className='fw-bold'>Tramo (Mts):</label>
                <div
                  className='col col-xs-3 col-sm-2 col-md-1 col-lg-1'
                  style={{ margin: '1%' }}>
                  <Form.Group>
                    <Form.Control
                      name='tramoMts'
                      plaintext
                      readOnly
                      value={esNumeroEntero(muestras.tramoMts)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className='d-block d-sm-block d-md-none'>
              <div className='d-flex align-items-center justify-content-end'>
                <div>
                  <Form.Group>
                    <Form.Label className='fw-bold'>Región:</Form.Label>
                    <Form.Control
                      className='mb-4'
                      name='region'
                      plaintext
                      readOnly
                      value={muestras.region}
                    />
                  </Form.Group>
                </div>
                <Form.Group>
                  <Form.Label className='fw-bold'>Proyecto:</Form.Label>
                  <Form.Control
                    className='mb-4'
                    name='proyecto'
                    plaintext
                    readOnly
                    value={muestras.proyecto}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>De (Mts):</Form.Label>
                  <Form.Control
                    className='mb-4'
                    name='deMts'
                    plaintext
                    readOnly
                    value={esNumeroEntero(muestras.deMts)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className='fw-bold'>A (Mts):</Form.Label>
                  <Form.Control
                    className='mb-4'
                    name='aMts'
                    plaintext
                    readOnly
                    value={esNumeroEntero(muestras.aMts)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>Tramo (Mts):</Form.Label>
                  <Form.Control
                    className='mb-5'
                    name='tramoMts'
                    plaintext
                    readOnly
                    value={esNumeroEntero(muestras.tramoMts)}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        ) : (
          <div className='text-center p-3'>
            <Alert variant='danger'>
              No se a encontrado el registro de los resultados de la Muestra
            </Alert>
          </div>
        )}
        <div style={{ marginBottom: '2%' }} className='row'>
          <Cabeza />
          <Preconcentrado />
          <Concentrado />
        </div>
        <ChartCabeza />
        <ChartPreconcentrado />
        <ChartConcentrado />
      </div>
    </>
  );
}
