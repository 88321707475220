import { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Card, Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import useSesion from '../../hooks/useSesion';
import { ROLES } from './../../contants/app-roles';
import { ITubo } from '../../interfaces/IResultados';
import listarTuboService from '../../services/Muestras/listtubo.service';
import { TuboService } from '../../services/Muestras/tubo.service';
import { agregarTuboService } from '../../services/Muestras/agregartubo.service';
import TubosModal from '../../modals/TuboModal';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';
import { tresDecimal } from './../../utils/numeroDecimal/tresDecimal';

const Concentrado = () => {
  const { role } = useSesion();
  const { id }: string | any = useParams();
  const [detalleT, setDetalleT] = useState<ITubo>({
    id: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    tuboPorRetenido: 0,
  });

  const [tubo, setTubo] = useState<ITubo[]>([]);

  const [showModalT, setShowModalT] = useState<boolean>(false);

  const openModalT = (values: ITubo) => {
    setDetalleT(values);
    setShowModalT(true);
  };
  /*Tubo*/
  const listarTubo = async () => {
    try {
      const data = await listarTuboService(id);
      setDetalleT(data);
    } catch (ex) {
      return ex;
    }
  };

  /*editar Tubo*/
  async function obtenerTubo() {
    try {
      const data = await TuboService();
      setTubo(data);
    } catch (ex) {}
  }
  function editarTubo(values: ITubo) {
    const actualizar = tubo.map((tub: ITubo) =>
      tub.id === values.id ? values : tub
    );
    setTubo(() => [...actualizar]);
    agregarTuboService(values);
    setDetalleT(values);
    setShowModalT(false);
  }
  useEffect(() => {
    listarTubo();
    obtenerTubo();
  }, []);

  return (
    <>
      {/* Card de concentrado */}
      <Card
        style={{ width: '54%' }}
        className='crd-color d-none d-md-none d-lg-block'>
        <Card.Body>
          <div className='d-flex bd-highlight justify-content-end'>
            <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'>
              Concentrado
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalT(detalleT)}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {detalleT ? (
            <div>
              <div className='row'>
                <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'></Card.Title>
                <Card.Title className='text-center tex-deco col d-block d-sm-block d-md-none'>
                  Concentrado
                </Card.Title>
                <Card.Title className='text-center tex-deco col d-none d-sm-none d-md-block'>
                  Gangas
                </Card.Title>
                <Card.Title className='text-end tex-deco col d-none d-sm-none d-md-block'>
                  Azufre
                </Card.Title>
              </div>

              <Card.Text>
                <div className='row tex-deco d-flex justify-content-center'>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      Gr Inicial
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboPesoInicial'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPesoInicial)} gr`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      Gr Final
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboPesoFinal'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPesoFinal)} gr`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='TuboPorRetenido'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPorRetenido)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboFeT'
                      plaintext
                      readOnly
                      value={`${detalleT.tuboFeT}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboFeM'
                      plaintext
                      readOnly
                      value={`${detalleT.tuboFeM}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Card.Title className='text-center tex-deco d-block d-sm-block d-md-none'>
                    Gangas
                  </Card.Title>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Al</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAluminio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAluminio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Ca</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboCalcio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboCalcio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Si</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboSilicio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboSilicio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Mg</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboMagnecio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboMagnecio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'
                    style={{ marginRight: '2%' }}>
                    <Form.Label className='tex-deco fw-bold'>Zn</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboZinc'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboZinc)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1 d-none d-sm-none d-md-block'>
                    <Form.Label className='tex-deco fw-bold'>S</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAzufre'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAzufre)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='d-block d-sm-block d-md-none'>
                  <Card.Title className='text-center tex-deco col'>
                    Azufre
                  </Card.Title>
                  <Form.Group className='text-center p-2 row'>
                    <Form.Label className='tex-deco fw-bold'>S</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAzufre'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAzufre)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-5'>
                No se a encontrado el registro de
                <span className='fw-bold'> Tubo</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      {/* Card de concentrado responsivo*/}
      <Card
        style={{ width: '99%' }}
        className='crd-color d-block d-md-block d-lg-none'>
        <Card.Body>
          <div className='d-flex bd-highlight justify-content-end'>
            <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'>
              Concentrado
            </Card.Title>
            <Card.Title className='text-center tex-deco col d-block d-sm-block d-md-none'>
              Concentrado
            </Card.Title>
            {role?.includes(ROLES.ADMINISTRADOR) && (
              <IconButton
                className='a-color p-2 bd-highlight '
                onClick={() => openModalT(detalleT)}>
                <EditIcon />
              </IconButton>
            )}
          </div>
          {detalleT ? (
            <div>
              <div className='row'>
                <Card.Title className='text-start tex-deco col d-none d-sm-none d-md-block'></Card.Title>
                <Card.Title className='text-center tex-deco col d-none d-sm-none d-md-block'>
                  Gangas
                </Card.Title>
                <Card.Title className='text-end tex-deco col d-none d-sm-none d-md-block'>
                  Azufre
                </Card.Title>
              </div>

              <Card.Text>
                <div className='row tex-deco d-flex justify-content-center'>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      Gr Inicial
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboPesoInicial'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPesoInicial)} gr`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-center p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>
                      Gr Final
                    </Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboPesoFinal'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPesoFinal)} gr`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>%RW</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='TuboPorRetenido'
                      plaintext
                      readOnly
                      value={`${esNumeroEntero(detalleT.tuboPorRetenido)}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeT</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboFeT'
                      plaintext
                      readOnly
                      value={`${detalleT.tuboFeT}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>FeM</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboFeM'
                      plaintext
                      readOnly
                      value={`${detalleT.tuboFeM}%`}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Card.Title className='text-center tex-deco d-block d-sm-block d-md-none'>
                    Gangas
                  </Card.Title>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Al</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAluminio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAluminio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Ca</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboCalcio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboCalcio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Si</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboSilicio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboSilicio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'>
                    <Form.Label className='tex-deco fw-bold'>Mg</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboMagnecio'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboMagnecio)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className='text-end p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1'
                    style={{ marginRight: '2%' }}>
                    <Form.Label className='tex-deco fw-bold'>Zn</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboZinc'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboZinc)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='p-2 col col-xs-2 col-sm-2 col-md-1 col-lg-1 d-none d-sm-none d-md-block'>
                    <Form.Label className='tex-deco fw-bold'>S</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAzufre'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAzufre)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='d-block d-sm-block d-md-none'>
                  <Card.Title className='text-center tex-deco col'>
                    Azufre
                  </Card.Title>
                  <Form.Group className='text-center p-2 row'>
                    <Form.Label className='tex-deco fw-bold'>S</Form.Label>
                    <Form.Control
                      className='text-center'
                      name='tuboAzufre'
                      plaintext
                      readOnly
                      value={tresDecimal(detalleT.tuboAzufre)}
                    />
                    <Form.Control.Feedback type='invalid'></Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Card.Text>
            </div>
          ) : (
            <div className='text-center'>
              <Alert variant='danger p-5'>
                No se a encontrado el registro de
                <span className='fw-bold'> Tubo</span>
              </Alert>
            </div>
          )}
        </Card.Body>
      </Card>
      <TubosModal
        detalle={detalleT}
        recuperar={editarTubo}
        estadoModal={showModalT}
        cambiarEstado={setShowModalT}
      />
    </>
  );
};

export default Concentrado;
