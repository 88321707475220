import { useEffect, useState } from 'react';
import { Form, Alert, Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { IMuestras } from './../../interfaces/IMuestras';
import { listarMuestrasService } from './../../services/Muestras/listmuestras.service';
import ChartCabeza from '../ListarResultados/charts/ChartCabeza';
import ChartConcentrado from '../ListarResultados/charts/ChartConcentrado';
import ChartPreconcentrado from '../ListarResultados/charts/ChartPreconcentrado';
import ChartGangas from '../ListarResultados/charts/ChartGangas';
import { esNumeroEntero } from './../../utils/numeroDecimal/esNumeroEntero';

export default function EtiquetaMuestraOne(props: any) {
  const { id }: string | any = useParams();
  const [muestras, setMuestra] = useState<IMuestras>({
    id: 0,
    idMuestra: '',
    proyecto: '',
    region: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboPorRetenido: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    fechaRegistroMuestra: '',
  });
  const listarMuestra = async () => {
    try {
      const data = await listarMuestrasService(id);
      setMuestra(data);
    } catch (ex) {
      return ex;
    }
  };
  useEffect(() => {
    listarMuestra();
  }, []);
  return (
    <>
      {muestras ? (
        <div className='m-5' ref={props.pdf}>
          <h2 className='text-center m-4 fw-bold'>{muestras.idMuestra}</h2>
          <div className='row text-center'>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                Fecha de Registro:
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {!!muestras.fechaRegistroMuestra
                  ? format(
                      new Date(muestras.fechaRegistroMuestra),
                      'dd/MM/yyyy'
                    )
                  : '--/--/----'}
              </Form.Label>
            </Form.Group>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                Region:
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {muestras.region}
              </Form.Label>
            </Form.Group>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                Proyecto:
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {muestras.proyecto}
              </Form.Label>
            </Form.Group>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                De (Mts):
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {esNumeroEntero(muestras.deMts)}
              </Form.Label>
            </Form.Group>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                A (Mts):
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {esNumeroEntero(muestras.aMts)}
              </Form.Label>
            </Form.Group>
            <Form.Group className='col'>
              <Form.Label className='fw-bold' style={{ fontSize: '15px' }}>
                Tramo (Mts):
              </Form.Label>
              <Form.Label style={{ fontSize: '15px' }}>
                {esNumeroEntero(muestras.tramoMts)}
              </Form.Label>
            </Form.Group>
          </div>
          <Form.Label className='fw-bold fs-5'>Resultados</Form.Label>
          <div className='row'>
            <div
              className='col'
              style={{
                width: '300px',
                marginTop: '-5px',
                padding: '30px',
              }}>
              <div style={{ marginBottom: '60px' }}>
                <Modal.Title className='text-center'>Cabeza</Modal.Title>
              </div>
              <ChartCabeza />
            </div>
            <div
              className='col'
              style={{ width: '300px', marginTop: '-10px', padding: '30px' }}>
              <Modal.Title className='text-center'>Preconcentrado</Modal.Title>
              <div className='d-flex flex-row text-center'>
                <Form.Group>
                  <Form.Label className='fw-bold'>Kg Inicial</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='prePesoInicial'
                    plaintext
                    readOnly
                    value={`${muestras.prePesoInicial} kg`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>KG Final</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='prePesoFinal'
                    plaintext
                    readOnly
                    value={`${muestras.prePesoFinal} kg`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>%RW</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='PrePorRetenido'
                    plaintext
                    readOnly
                    value={`${esNumeroEntero(muestras.prePorRetenido)}%`}
                  />
                </Form.Group>
              </div>
              <ChartPreconcentrado />
            </div>
            <div
              className='col'
              style={{ width: '300px', marginTop: '-10px', padding: '30px' }}>
              <Modal.Title className='text-center'>Concentrado</Modal.Title>
              <div className='d-flex flex-row text-center'>
                <Form.Group>
                  <Form.Label className='fw-bold'>Kg Inicial</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='prePesoInicial'
                    plaintext
                    readOnly
                    value={`${muestras.tuboPesoInicial} gr`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>KG Final</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='prePesoFinal'
                    plaintext
                    readOnly
                    value={`${muestras.tuboPesoFinal} gr`}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className='fw-bold'>%RW</Form.Label>
                  <Form.Control
                    className='text-center'
                    name='PrePorRetenido'
                    plaintext
                    readOnly
                    value={`${esNumeroEntero(muestras.tuboPorRetenido)}%`}
                  />
                </Form.Group>
              </div>
              <ChartConcentrado />
            </div>
            <div
              className='col'
              style={{ width: '300px', marginTop: '-10px', padding: '30px' }}>
              <Modal.Title className='text-center'>Gangas</Modal.Title>
              <ChartGangas />
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center p-3'>
          <Alert variant='danger'>
            No se a encontrado el registro de los resultados de la Muestra
          </Alert>
        </div>
      )}
    </>
  );
}
