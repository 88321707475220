import axios from './../axios';

export async function usuariosService() {
  try {
    const { data } = await axios.get('User');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export default usuariosService;
