import axios from '../../axios';
import { IAddMuestras } from '../../interfaces/IAddMuestras';

export const agregarMuestrasService = async (dataRequest: IAddMuestras) => {
  try {
    const { data } = await axios.post('Muestras', dataRequest);
    return data;
  } catch (ex) {
    throw ex;
  }
};
