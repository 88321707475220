import axios from '../../axios';

export async function TuboService() {
  try {
    const { data } = await axios.get('Muestras/Tubo');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export default TuboService;
