import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

import { AppRoutes } from '../../contants/app-routes';
import { ROLES } from './../../contants/app-roles';
import useSesion from '../../hooks/useSesion';
import Logo from './../../assets/images/logo';
import { cerrarSesion } from './../../services/login.service';

import './NavMenu.scss';

export default function NavMenu() {
  const path = window.location.pathname;
  const { usuario, setUsuario, setRole, role } = useSesion();
  const logOut = () => {
    setRole(null);
    setUsuario(null);
    cerrarSesion();
  };
  return (
    <Navbar
      className='container-nav d-flex align-items-center justify-content-between p-1'
      variant='dark'>
      <Container fluid>
        <Navbar.Brand>
          <Logo width='140' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse className='justify-content-end' id='basic-navbar-nav'>
          <NavDropdown
            id='basic-nav-dropdown'
            title={usuario}
            flip={true}
            align='end'>
            {(role?.includes(ROLES.USUARIO) &&
              !!!path.includes('/DetalleMuestra')) ||
              (role?.includes(ROLES.ADMINISTRADOR) && (
                <>
                  {role?.includes(ROLES.ADMINISTRADOR) && (
                    <>
                      <Nav.Link
                        id='nav-link-item'
                        as={Link}
                        to={AppRoutes.Usuarios}>
                        Usuarios
                      </Nav.Link>
                      <NavDropdown.Divider />
                    </>
                  )}
                </>
              ))}
            <Nav.Link id='nav-link-item' onClick={() => logOut()}>
              Cerrar Sesion
            </Nav.Link>
          </NavDropdown>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
