import { useState } from 'react';
import { useFormik } from 'formik';
import { Modal, Form, Button } from 'react-bootstrap';

import { agregarUsuarioService } from '../services/agregarusuario.service';
import { IAddUser } from '../interfaces/IAddUser';
import { AddUserModal } from './../utils/types/type';
import { addUserValidaciones } from './../utils/validaciones/adduser.validaciones';

import './AgregarUsuarioModal.scss';
import useError from '../hooks/useError';

export default function AgregarUsuarioModal({
  estadoModal,
  recuperar,
  cambiarEstado,
}: AddUserModal) {
  const initialValues: IAddUser = {
    userName: '',
    email: '',
  };
  const { setError, ErrorAlert } = useError();
  const [, setAddUser] = useState();

  const obtenerFormik = async (values: IAddUser) => {
    try {
      const data = await agregarUsuarioService(values);
      if (data) {
        setAddUser(data);
        recuperar(data);
        cambiarEstado(false);
        formik.resetForm();
      }
    } catch (ex) {
      setError(ex);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addUserValidaciones,
    onSubmit: async (values: IAddUser) => {
      obtenerFormik(values);
    },
  });
  return (
    <Modal show={estadoModal} onHide={() => cambiarEstado(false)}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Usuario</Modal.Title>
        </Modal.Header>
        <div className='m-4'>
          <ErrorAlert />
        </div>
        <Form.Group className='row d-flex justify-content-center m-4'>
          <Form.Label>Usuario</Form.Label>
          <Form.Control
            autoFocus={true}
            name='userName'
            placeholder='Usuario'
            onChange={formik.handleChange}
            value={formik.values.userName}
            isInvalid={!!formik.errors.userName}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.userName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='row d-flex justify-content-center m-4'>
          <Form.Label>Correo</Form.Label>
          <Form.Control
            name='email'
            placeholder='Correo'
            type='email'
            onChange={formik.handleChange}
            value={formik.values.email}
            isInvalid={!!formik.errors.email}
          />
          <Form.Control.Feedback type='invalid'>
            {formik.errors.email}
          </Form.Control.Feedback>
        </Form.Group>
        <Modal.Footer>
          <Button
            className='btn-light border-dark'
            onClick={() => (
              cambiarEstado(false), formik.resetForm(), setError('')
            )}>
            Cerrar
          </Button>
          <Button type='submit' className='btn-success'>
            Guardar
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
