import { useFormik } from 'formik';
import { Form, Button, Modal } from 'react-bootstrap';
import useError from '../hooks/useError';
import { agregarMuestrasService } from '../services/Muestras/agregarmuestras.service';
import { IAddMuestras } from './../interfaces/IAddMuestras';
import { MuestraModal } from './../utils/types/type';
import { muestrasValidaciones } from './../utils/validaciones/muestras.validaciones';

import './ModalMuestra.scss';

export default function MuestrasModal({
  estadoModal,
  recuperar,
  cambiarEstado,
}: MuestraModal) {
  const initialValues: IAddMuestras = {
    idMuestra: '',
    region: '',
    proyecto: '',
    deMts: 0,
    aMts: 0,
    tramoMts: 0,
  };
  const { setError, ErrorAlert } = useError();

  const obtenerFormik = async (values: IAddMuestras) => {
    try {
      const data = await agregarMuestrasService(values);
      if (data) {
        recuperar(data);
        cambiarEstado(false);
        window.location.reload();
        formik.resetForm();
      }
    } catch (ex) {
      setError(ex);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: muestrasValidaciones,
    onSubmit: async (values: IAddMuestras) => {
      obtenerFormik(values);
    },
  });

  return (
    <>
      <Modal show={estadoModal} onHide={() => cambiarEstado(false)} centered>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Muestra</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ErrorAlert />
            <div className='row'>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>IdMuestras</Form.Label>
                <Form.Control
                  autoFocus={true}
                  name='idMuestra'
                  placeholder='MNA0000-00000'
                  isInvalid={!!formik.errors.idMuestra}
                  onChange={formik.handleChange}
                  value={formik.values.idMuestra}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.idMuestra}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>Región</Form.Label>
                <Form.Control
                  name='region'
                  placeholder='Ejemplo Mina'
                  isInvalid={!!formik.errors.region}
                  onChange={formik.handleChange}
                  value={formik.values.region}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.region}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>Proyecto</Form.Label>
                <Form.Control
                  name='proyecto'
                  placeholder='Poyecto Ejemplo'
                  isInvalid={!!formik.errors.proyecto}
                  onChange={formik.handleChange}
                  value={formik.values.proyecto}
                  onFocus={e => e.currentTarget.select()}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.proyecto}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>De (Mts)</Form.Label>
                <Form.Control
                  name='deMts'
                  type='number'
                  onChange={formik.handleChange}
                  value={formik.values.deMts}
                  onFocus={e => e.currentTarget.select()}
                />
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>A (Mts)</Form.Label>
                <Form.Control
                  name='aMts'
                  type='number'
                  onChange={formik.handleChange}
                  value={formik.values.aMts}
                  onFocus={e => e.currentTarget.select()}
                />
              </Form.Group>
              <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                <Form.Label>Tramo (Mts)</Form.Label>
                <Form.Control
                  name='tramoMts'
                  type='number'
                  readOnly
                  disabled
                  onChange={formik.handleChange}
                  value={
                    (formik.values.tramoMts =
                      formik.values.aMts - formik.values.deMts)
                  }
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='br-cg'
              variant='btn btn-outline-dark'
              onClick={() => (
                cambiarEstado(false), formik.resetForm(), setError('')
              )}>
              Cancelar
            </Button>
            <Button className='boton-gris' type='submit'>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
