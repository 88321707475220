import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Modal, Alert } from 'react-bootstrap';
import { AxiosResponse } from 'axios';

import axios from '../axios';
import { CabezaModal } from './../utils/types/type';
import { ICabeza } from '../interfaces/IResultados';
import { agregarCabezaService } from '../services/Muestras/agregarcabeza.service';
import { resultadoC } from './../utils/validaciones/resultadoC.validaciones';

export default function CabezasModal({
  detalle,
  estadoModal,
  recuperar,
  cambiarEstado,
}: CabezaModal) {
  const [cabezas, setCabeza] = useState<ICabeza>({
    id: 0,
    cabezaFeT: 0,
    cabezaFeM: 0,
  });

  const formik = useFormik({
    initialValues: cabezas,
    validationSchema: resultadoC,
    onSubmit: async (values: ICabeza) => {
      const { data } = await agregarCabezaService(values);
      setCabeza(data);
      recuperar(data);
      cambiarEstado(false);
      window.location.reload();
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (estadoModal) {
      axios
        .get(`Muestras/${+detalle.id}`)
        .then((respuesta: AxiosResponse<ICabeza>) => {
          setCabeza(respuesta.data);
          formik.setValues({
            id: respuesta.data.id,
            cabezaFeM: respuesta.data.cabezaFeM,
            cabezaFeT: respuesta.data.cabezaFeT,
          });
        });
    }
  }, [estadoModal]);

  return (
    <>
      <Modal
        show={estadoModal}
        onHide={() => cambiarEstado(false)}
        centered
        style={{ marginRight: '1%' }}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className='tex-deco'>Cabeza</Modal.Title>
          </Modal.Header>
          {cabezas ? (
            <>
              <Modal.Body>
                <Form.Group className='text-left p-2 col col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <Form.Label className='tex-deco'>FeT</Form.Label>
                  <Form.Control
                    autoFocus={true}
                    name='cabezaFeT'
                    type='number'
                    onFocus={e => e.currentTarget.select()}
                    isInvalid={!!formik.errors.cabezaFeT}
                    onChange={formik.handleChange}
                    value={formik.values.cabezaFeT}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.cabezaFeT}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='text-left p-2 col col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <Form.Label className='tex-deco'>FeM</Form.Label>
                  <Form.Control
                    name='cabezaFeM'
                    type='number'
                    onFocus={e => e.currentTarget.select()}
                    isInvalid={!!formik.errors.cabezaFeM}
                    onChange={formik.handleChange}
                    value={formik.values.cabezaFeM}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {formik.errors.cabezaFeM}
                  </Form.Control.Feedback>
                </Form.Group>
              </Modal.Body>
            </>
          ) : (
            <div className='text-center p-3'>
              <Alert variant='danger'>
                no se a encontrado el registro de cabeza
              </Alert>
            </div>
          )}
          <Modal.Footer>
            <Button
              className='br-cg'
              variant='btn btn-outline-dark'
              onClick={() => cambiarEstado(false)}>
              Cancelar
            </Button>
            <Button className='boton-gris' type='submit'>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
