import * as yup from 'yup';

export const resultadoP = yup.object({
  preFeT: yup.number().min(0, 'Valor Minimo 0'),
  preFeM: yup.number().min(0, 'Valor Minimo 0'),
  prePesoInicial: yup
    .number()
    .min(0, 'Valor Minimo 0')
    .required('Campo obligatorio'),
  prePesoFinal: yup
    .number()
    .min(0, 'Valor Minimo 0')
    .required('Campo obligatorio'),
});
