import { LoadingProps } from '../utils/types/loading.type';
import { Spinner } from 'react-bootstrap';

import './Loading.scss';

export default function Loading(props: LoadingProps) {
  const { mensaje } = props;

  return (
    <div className='loading'>
      <Spinner
        className='loading__spinner'
        animation='border'
        variant='secondary'
      />
      <p className='loading__mensaje'>{mensaje}</p>
    </div>
  );
}
