const getErrors = (error: any) => {
    const errors = error.response?.data?.errors;
  
    if (!errors) return null;
  
    return Object.keys(errors)
      .reduce((acc:any, current:any) => {
        acc.push(...errors[current]);
        return acc;
      }, [])
      .join(', ');
  };
  export const getErrorMessage = error =>
  error.response?.data?.message || getErrors(error) || error.message || error;