import { ReactElement, useState, useEffect } from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './contants/app-routes';
import SesionContext from './context/SesionContext';
import { ISesionContext } from './interfaces/ISesionContext';
import Login from './pages/Login/Login';
import ListaMuestra from './pages/ListaMuestra/ListaMuestra';
import NavMenu from './components/NavBar/NavMenu';
import Usuarios from './pages/Usuarios/Usuarios';
import Footer from './components/Footer/Footer';
import ListarResultados from './pages/ListarResultados/ListarResultados';
import EtiquetaMuestra from './pages/EtiquetaMuestra/EtiquetaMuestra';
import EtiquetaMuestraOne from './pages/EtiquetaMuestra/EtiquetaMuestraOne';

import './App.scss';

function App() {
  const storage = localStorage.getItem('credenciales');
  const [usuario, setUsuario] = useState<string | null>(
    storage ? JSON.parse(storage).usuario : null
  );
  const [role, setRole] = useState<string | null>(
    storage ? JSON.parse(storage).roles : null
  );

  const contextData: ISesionContext = {
    usuario,
    setUsuario,
    role,
    setRole,
  };
  const navegacion = (elemento: ReactElement) => {
    if (!!usuario) {
      return elemento;
    } else {
      return <Navigate to={AppRoutes.Root} />;
    }
  };
  useEffect(() => {}, [role]);
  return (
    <SesionContext.Provider value={contextData}>
      <BrowserRouter>
        {!!usuario && <NavMenu />}
        <Routes>
          <Route
            path={AppRoutes.Root}
            element={
              !!usuario ? <Navigate to={AppRoutes.ListaMuestra} /> : <Login />
            }
          />
          <Route
            path={AppRoutes.ListaMuestra}
            element={navegacion(<ListaMuestra />)}
          />
          <Route path={AppRoutes.Usuarios} element={navegacion(<Usuarios />)} />
          <Route
            path={AppRoutes.ListarResultados}
            element={navegacion(<ListarResultados />)}
          />
          <Route
            path={AppRoutes.EtiquetaMuestra}
            element={navegacion(<EtiquetaMuestra />)}
          />
          <Route
            path={AppRoutes.EtiquetaMuestraOne}
            element={navegacion(<EtiquetaMuestraOne />)}
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </SesionContext.Provider>
  );
}

export default App;
