import axios from '../../axios';

export async function PreconcentradoSevice() {
  try {
    const { data } = await axios.get('Muestras/Preconcentrado');
    return data;
  } catch (ex) {
    throw ex;
  }
}

export default PreconcentradoSevice;
