import axios from '../../axios';
import { IDMuestra } from '../../interfaces/IMuestras';

export const borrarMuestraService = async (dataRequest: IDMuestra) => {
  try {
    const { data } = await axios.delete(`Muestras/${dataRequest.id}`);
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
};
