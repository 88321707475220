import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Modal, Alert } from 'react-bootstrap';

import { PreconcentradoModal } from './../utils/types/type';
import { IPreconcentrado } from '../interfaces/IResultados';
import { agregarPreconcentradoService } from '../services/Muestras/agregarpreconcentrado';
import axios from '../axios';
import { AxiosResponse } from 'axios';
import { resultadoP } from './../utils/validaciones/resultadoP.validaciones';

export default function PreconcentradosModal({
  detalle,
  estadoModal,
  recuperar,
  cambiarEstado,
}: PreconcentradoModal) {
  const [preconcentrados, setPreconcentrado] = useState<IPreconcentrado>({
    id: 0,
    preFeT: 0,
    preFeM: 0,
    prePesoInicial: 0,
    prePesoFinal: 0,
    prePorRetenido: 0,
  });

  const formik = useFormik({
    initialValues: preconcentrados,
    validationSchema: resultadoP,
    onSubmit: async (values: IPreconcentrado) => {
      const { data } = await agregarPreconcentradoService(values);
      setPreconcentrado(data);
      recuperar(data);
      cambiarEstado(false);
      window.location.reload();
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (estadoModal) {
      axios
        .get(`Muestras/${+detalle.id}`)
        .then((respuesta: AxiosResponse<IPreconcentrado>) => {
          setPreconcentrado(respuesta.data);
          formik.setValues({
            id: respuesta.data.id,
            preFeT: respuesta.data.preFeT,
            preFeM: respuesta.data.preFeM,
            prePesoInicial: respuesta.data.prePesoInicial,
            prePesoFinal: respuesta.data.prePesoFinal,
            prePorRetenido: respuesta.data.prePorRetenido,
          });
        });
    }
  }, [estadoModal]);

  return (
    <>
      <Modal show={estadoModal} onHide={() => cambiarEstado(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className='tex-deco'>Preconcentrado</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          {preconcentrados ? (
            <>
              <Modal.Body style={{ marginTop: '-48px', marginBottom: '30px' }}>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco'>Peso Inicial</Form.Label>
                    <Form.Control
                      autoFocus={true}
                      name='prePesoInicial'
                      type='number'
                      onFocus={e => e.currentTarget.select()}
                      isInvalid={!!formik.errors.prePesoInicial}
                      onChange={formik.handleChange}
                      value={formik.values.prePesoInicial}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.prePesoInicial}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco'>Peso Final</Form.Label>
                    <Form.Control
                      name='prePesoFinal'
                      type='number'
                      onFocus={e => e.currentTarget.select()}
                      isInvalid={!!formik.errors.prePesoFinal}
                      onChange={formik.handleChange}
                      value={formik.values.prePesoFinal}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.prePesoFinal}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco'>%RW</Form.Label>
                    <Form.Control
                      name='PrePorRetenido'
                      type='number'
                      readOnly
                      disabled
                      onChange={formik.handleChange}
                      value={
                        (formik.values.prePorRetenido =
                          (formik.values.prePesoFinal * 100) /
                          formik.values.prePesoInicial)
                      }
                    />
                  </Form.Group>
                </div>
                <div className='row'>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco'>FeT</Form.Label>
                    <Form.Control
                      name='preFeT'
                      type='number'
                      isInvalid={!!formik.errors.preFeT}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.preFeT}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.preFeT}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='text-left p-2 col col-xs-6 col-sm-6 col-md-6 col-lg-6'>
                    <Form.Label className='tex-deco'>FeM</Form.Label>
                    <Form.Control
                      name='preFeM'
                      type='number'
                      isInvalid={!!formik.errors.preFeM}
                      onFocus={e => e.currentTarget.select()}
                      onChange={formik.handleChange}
                      value={formik.values.preFeM}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {formik.errors.preFeM}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </Modal.Body>
            </>
          ) : (
            <div className='text-center p-3'>
              <Alert variant='danger'>
                No se a encontrado el registro del preconcentrado
              </Alert>
            </div>
          )}
          <Modal.Footer>
            <Button
              className='br-cg'
              variant='btn btn-outline-dark'
              onClick={() => cambiarEstado(false)}>
              Cancelar
            </Button>
            <Button className='boton-gris' type='submit'>
              Guardar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
