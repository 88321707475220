import { useEffect, useState } from 'react';
import { Card, Form, Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';

import { ILogin } from '../../interfaces/ILogin';
import { loginService } from '../../services/login.service';
import { loginValidaciones } from '../../utils/validaciones/login.validaciones';
import useSesion from '../../hooks/useSesion';
import useError from './../../hooks/useError';
import Logo from '../../assets/images/logo';

import './Login.scss';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const { setError, ErrorAlert } = useError();
  const { setUsuario, setRole } = useSesion();
  const initialValues: ILogin = {
    username: '',
    password: '',
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginValidaciones,
    onSubmit: async (values: ILogin) => {
      try {
        setLoading(true);
        const data = await loginService(values);
        if (data) {
          setUsuario(data.usuario);
          setRole(data.roles);
        }
      } catch (ex) {
        setError(ex);
      } finally {
        setLoading(false);
      }
    },
  });
  useEffect(() => {
    setLoading(false);
    setError('');
  }, [formik.values.password]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='d-flex row justify-content-center p-4'>
        <div className='col-xs-12 col-md-8 col-sm-12 mx-0 my-auto'>
          <Logo />
        </div>
        <Card className='p-4 my-auto col-xs-12 col-sm-12 col-md-4 col-lg-4'>
          <ErrorAlert />
          <h3 className='text-start'>Iniciar Sesión</h3>
          <Form.Group className='mt-3'>
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              name='username'
              placeholder='Usuario'
              onChange={formik.handleChange}
              value={formik.values.username}
              isInvalid={!!formik.errors.username}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <Form.Group>
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              id='password'
              name='password'
              placeholder='Contraseña'
              type='password'
              onChange={formik.handleChange}
              value={formik.values.password}
              isInvalid={!!formik.errors.password}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>
          <br />
          <Button className='mt-1 mb-3' variant='secondary' type='submit'>
            {loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
                &nbsp;&nbsp;{'Iniciando Sesión...'}
              </>
            ) : (
              'Iniciar Sesión'
            )}
          </Button>
        </Card>
      </div>
    </form>
  );
}
