import { ILogin } from '../interfaces/ILogin';
import axios from './../axios';

export const loginService = async (dataRequest: ILogin) => {
  try {
    const { data } = await axios.post('Auth/login', dataRequest);
    localStorage.setItem('credenciales', JSON.stringify(data));
    return data;
  } catch (ex) {
    throw ex;
  }
};
export const cerrarSesion = () => {
  localStorage.removeItem('credenciales');
};
