import { useEffect, useState } from 'react';
import { modelolink, paginacionProps } from '../../interfaces/IPaginado';

import './Paginacion.scss';
export default function Paginacion(props: paginacionProps) {
  const [listadoLinks, setListadoLinks] = useState<modelolink[]>([]);

  function obtenerClase(link: modelolink) {
    if (link.activo) {
      return 'active poiter';
    }
    if (!link.habilitado) {
      return 'disabled';
    }
    return 'pointer';
  }

  function seleccionarPagina(link: modelolink) {
    if (link.pagina === props.paginaActual) {
      return;
    }
    if (!link.habilitado) {
      return;
    }
    props.onChange(link.pagina);
  }

  const obtenerPaginado = () => {
    const paginaAnteriorHabilitada = props.paginaActual !== 1;
    const paginaAnterior = props.paginaActual - 1;

    let links: modelolink[] = [];
    links = [
      ...links,
      {
        texto: 'Anterior',
        habilitado: paginaAnteriorHabilitada,
        pagina: paginaAnterior,
        activo: false,
      },
    ];

    for (let i = 1; i <= props.cantidadTotalDePaginas; i++) {
      if (
        i >= props.paginaActual - props.radio &&
        i <= props.paginaActual + props.radio
      ) {
        links = [
          ...links,
          {
            texto: `${i}`,
            activo: props.paginaActual === i,
            habilitado: true,
            pagina: i,
          },
        ];
      }
    }

    const paginaSiguienteHabilitada =
      props.paginaActual !== props.cantidadTotalDePaginas &&
      props.cantidadTotalDePaginas > 0;
    const paginaSiguiente = props.paginaActual + 1;
    links = [
      ...links,
      {
        texto: 'Siguiente',
        pagina: paginaSiguiente,
        habilitado: paginaSiguienteHabilitada,
        activo: false,
      },
    ];
    setListadoLinks(links);
  };

  useEffect(() => {
    obtenerPaginado();
  }, [props.paginaActual, props.cantidadTotalDePaginas, props.radio]);

  return (
    <nav>
      <ul className='pagination justify-content-center'>
        {listadoLinks.map(link => (
          <li
            key={link.texto}
            onClick={() => seleccionarPagina(link)}
            className={`page-item cursor ${obtenerClase(link)}`}>
            <span className='page-link link-dark'>{link.texto}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

Paginacion.defaultProps = {
  radio: 2,
};
