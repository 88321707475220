import axios from './../axios';
import { IFiltros } from './../interfaces/IFiltros';

export const exportExcelService = async (filtros: IFiltros) => {
  try {
    const { data } = await axios.get(
      `Muestras/ExportMuestras?initialDate=${filtros.initialDate}&finalDate=${filtros.finalDate}`,
      {
        responseType: 'blob',
      }
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
};
