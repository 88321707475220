import axios from '../../axios';
import { IPreconcentrado } from '../../interfaces/IResultados';

export async function agregarPreconcentradoService(
  PrecoEditar: IPreconcentrado
) {
  try {
    const { data } = await axios.put(
      `Muestras/Preconcentrado/${PrecoEditar.id}`,
      PrecoEditar
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
}
