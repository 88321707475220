import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { ITubo } from '../../../interfaces/IResultados';
import listarTuboService from '../../../services/Muestras/listtubo.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { tresDecimal } from './../../../utils/numeroDecimal/tresDecimal';
ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);
export const optionsG = {
  plugins: {
    legend: {
      labels: {
        generateLabels: chart => {
          const datasets = chart.data.datasets;
          return datasets[0].data.map((data, i) => ({
            text: `${chart.data.labels[i]}: ${tresDecimal(data)}%`,
            fillStyle: datasets[0].backgroundColor[i],
          }));
        },
      },
    },
  },
};

const ChartWrap = styled.div`
  max-width: 500px;
`;

const ChartGangas = () => {
  const { id }: string | any = useParams();
  const [detalleT, setDetalleT] = useState<ITubo>({
    id: 0,
    tuboFeT: 0,
    tuboFeM: 0,
    tuboPesoInicial: 0,
    tuboPesoFinal: 0,
    tuboAluminio: 0,
    tuboCalcio: 0,
    tuboSilicio: 0,
    tuboMagnecio: 0,
    tuboZinc: 0,
    tuboAzufre: 0,
    tuboPorRetenido: 0,
  });

  /*Tubo*/
  const listarTubo = async () => {
    try {
      const data = await listarTuboService(id);
      setDetalleT(data);
    } catch (ex) {
      return ex;
    }
  };

  useEffect(() => {
    listarTubo();
  }, []);

  const gangasChartData = () => {
    const data = [
      detalleT.tuboAluminio,
      detalleT.tuboCalcio,
      detalleT.tuboSilicio,
      detalleT.tuboMagnecio,
      detalleT.tuboZinc,
      detalleT.tuboAzufre,
    ];
    const labels = ['Al', 'Ca', 'Si', 'Mg', 'Zn', 'S'];

    return {
      labels,
      datasets: [
        {
          data,
          borderColor: [
            '#183412 ',
            '#595A16 ',
            '#365F62 ',
            '#151C16 ',
            '#9E9045 ',
            '#8B6E08 ',
          ],
          backgroundColor: [
            '#183412 ',
            '#595A16 ',
            '#365F62 ',
            '#151C16 ',
            '#9E9045 ',
            '#8B6E08 ',
          ],
          datalabels: {
            formatter: value => {
              return '';
            },
          },
        },
      ],
    };
  };
  return (
    <ChartWrap>
      <Pie options={optionsG} data={gangasChartData()} width={40} height={20} />
    </ChartWrap>
  );
};

export default ChartGangas;
