import axios from '../../axios';
import { ITubo } from '../../interfaces/IResultados';

export async function agregarTuboService(tuboEditar: ITubo) {
  try {
    const { data } = await axios.put(
      `Muestras/Tubo/${tuboEditar.id}`,
      tuboEditar
    );
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
}
