import axios from './../axios';
import { IDeleteUser } from './../interfaces/IDeleteUser';

export const borrarUsuarioService = async (dataRequest: IDeleteUser) => {
  try {
    const { data } = await axios.delete(`User/${dataRequest.id}`);
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
};
