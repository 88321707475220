import { createContext } from 'react';

import { ISesionContext } from '../interfaces/ISesionContext';

const SesionContext = createContext<ISesionContext>({
  usuario: null,
  setUsuario: () => {},
  role: null,
  setRole: () => {},
});

export default SesionContext;
